import React from 'react';

import { AbstractRoute } from '@/core/lib/router/route';
import AdSense from '@/next/lib/ads/AdSense';
import YieldLoveNoLazyLoad from '@/next/lib/ads/YieldLoveNoLazyLoad';

const AdsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    common: { adsense },
  } = AbstractRoute.useCommonParams();

  if (adsense === 1) {
    return <AdSense>{children}</AdSense>;
  }

  return <YieldLoveNoLazyLoad>{children}</YieldLoveNoLazyLoad>;
};

export default AdsProvider;
