import groupBy from 'lodash/groupBy';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import Alert from '@/core/components/Alert';
import Button from '@/core/components/Button';
import FormContainer from '@/core/components/FormContainer';
import Header from '@/core/components/Header';
import HintMessage from '@/core/components/HintMessage';
import type { IconName } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import Info from '@/core/components/Info';
import CategoryInput from '@/core/components/inputs/CategoryInput';
import CityInput from '@/core/components/inputs/CityInput';
import PhotoInput from '@/core/components/inputs/PhotoInput';
import SelectInput from '@/core/components/inputs/SelectInput';
import TextAreaInput from '@/core/components/inputs/TextAreaInput';
import TextInput from '@/core/components/inputs/TextInput';
import { ToastStatus } from '@/core/components/Toast/Toast';
import useForm from '@/core/hooks/useForm';
import useOpenGive from '@/core/hooks/useOpenGive';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { hasNotUrl } from '@/core/lib/form/validators';
import { donationToAlias } from '@/core/lib/geolocation/formatter';
import Actions from '@/core/lib/new-architecture/actions';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import { PHOTO_REGEX } from '@/core/lib/regex';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { DonationDonationForm, PrivateDonationItemResult } from '@/core/types/donation';
import { donationsCategoryResultSchema } from '@/core/types/donation';
import type { UploadResult } from '@/core/types/donnons';
import { uploadResultSchema } from '@/core/types/donnons';
import { locationFormSchema } from '@/core/types/geo';

export const localDonationFormSchema = yup.object({
  title: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).required('errors.inputs.required'),
  category: donationsCategoryResultSchema.required('errors.inputs.required'),
  description: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).nullable().max(1000, 'errors.inputs.max-1000'),
  location: locationFormSchema.required('errors.inputs.required'),
  signature: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).nullable().max(400, 'errors.inputs.max-400'),
  photos: yup.array(uploadResultSchema.required()).required('errors.inputs.required'),
  clothes: yup.string().nullable(),
  shoes: yup.string().nullable(),
  bra: yup.string().nullable(),
  fashion_accessory: yup.string().nullable(),
  state_option: yup.string().nullable(),
  dlc: yup.string().nullable(),
});

export type LocalDonationForm = yup.InferType<typeof localDonationFormSchema>;

type AvailableStep = 'photos' | 'title&category' | 'rest' | 'published';

interface DonationFormProps {
  donation?: PrivateDonationItemResult;
  onSuccess?: () => void;
}

interface FormLabelProps {
  label: string;
  icon: IconName;
  htmlFor: string;
  optional?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({ icon, label, htmlFor, optional = false }) => {
  const { t } = useTranslationContext(['donation.create']);
  const ns = 'donation.create';

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2">
        <Icons icon={icon} size="20" />
        <label htmlFor={htmlFor} className="text-body-secondary font-semibold text-content-primary">
          {label}
        </label>
      </div>
      {optional && (
        <div className="max-w-fit rounded-1 bg-bg-tag-status-booked p-1">
          <p className="text-caption-primary font-medium text-content-tag-status-booked">{t('form.rest.optional', { ns })}</p>
        </div>
      )}
    </div>
  );
};

const ProgressDot: React.FC<{ currentStep: AvailableStep }> = ({ currentStep }) => {
  const steps: AvailableStep[] = ['photos', 'title&category', 'rest'];

  return (
    <div className="flex w-full items-center justify-center gap-2">
      {steps.map(item => (
        <div key={item} className={`size-2 rounded-full ${currentStep === item ? 'bg-content-secondary' : 'bg-button-primary-default border border-bg-secondary'}`} />
      ))}
    </div>
  );
};

const DonationForm: React.FC<DonationFormProps> = ({ donation, onSuccess: onSuccessProps }) => {
  const ns = donation ? 'donation.edit' : 'donation.create';
  const { t } = useTranslationContext([ns]);

  const { send } = useAnalyticsContext();
  const toast = useToastContext();
  const [donationId, setDonationId] = useState<number | undefined>(undefined);
  const { push, back } = useRouterContext();

  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();

  const { data: current } = Store.current.useCurrent();

  const isCreationDonationType = !donation;

  const { onCloseGive } = useOpenGive();

  const initializedStep: AvailableStep = 'photos';
  const [step, setStep] = useState<AvailableStep>(initializedStep);

  const [showPhotosErrors, setShowPhotosErrors] = useState(false);
  const [hasPhotosErrorsBeenClosed, setHasPhotosErrorsBeenClosed] = useState(false);

  const { onCreate } = Actions.donation.useCreateDonation();
  const { onModify } = Actions.donation.useModifyDonation(donation);

  const { data: categories } = Store.categories.useCategories();

  const valuesFromUser = useMemo(() => {
    if (!current) return undefined;

    const loc = current.getLoc();
    const { signature } = current.getInfo();

    return {
      location: loc?.getLocationForm(),
      signature,
    };
  }, [current]);

  const cat = donation ? categories?.findById(donation.donation.cat) : null;

  const values =
    donation && cat
      ? ({
          title: donation.donation.title,
          category: cat.getDonationsCategoryResult(),
          description: donation.donation.description,
          location: {
            loc: donationToAlias(donation) ?? '',
            lat: donation.donation.loc.lat,
            lon: donation.donation.loc.lon,
          },
          signature: donation.donation.signature,
          photos: donation.donation.photos.reduce((acc, val) => {
            const match = val.match(PHOTO_REGEX);

            if (match) {
              return [...acc, match[1]];
            }

            return acc;
          }, [] as string[]),
          ...donation.options?.reduce((acc, val) => ({ ...acc, [val.name]: val.value }), {}),
        } satisfies DonationDonationForm)
      : undefined;

  const { control, errors, onSubmit, isLoading, onCleanGlobalError, watch, reset } = useForm<PrivateDonationItemResult, DonationDonationForm, LocalDonationForm>({
    schema: localDonationFormSchema,
    transformers: {
      formToApi: form => ({ ...form, photos: form.photos.map(photo => photo.uuid) }),
      apiToForm: data => ({
        ...data,
        photos: (data?.photos ?? []).reduce((acc, uuid) => {
          const photos = donation?.donation.photos;

          const url = photos?.find(photo => {
            if (!uuid || !photo.includes(uuid)) return false;

            const match = photo.match(PHOTO_REGEX);

            if (match) return true;

            return false;
          });

          if (url && uuid) {
            return [...acc, { url, uuid }];
          }

          return acc;
        }, [] as UploadResult[]),
      }),
    },
    mutationFn: data => {
      setHasPhotosErrorsBeenClosed(false);
      return isCreationDonationType ? onCreate(data) : onModify(data);
    },
    values,
    onSuccess: async data => {
      send({ event: donation ? AnalyticsEventType.POST_UPDATED : AnalyticsEventType.POST_CREATED, category_name: categories?.findById(data.donation.cat)?.label as string });
      if (onSuccessProps) {
        onSuccessProps();
      }
      if (isCreationDonationType) {
        setDonationId(data.donation.id);
        setStep('published');
        reset();
      } else {
        reset();
        setStep(initializedStep);
        onCloseGive();
        toast?.open(t('form.toast.success', { ns: 'donation.edit' }), 5000, ToastStatus.SUCCESS);
      }
    },
  });

  const { photos: photosError } = errors;
  useEffect(() => {
    if (!hasPhotosErrorsBeenClosed && !showPhotosErrors && photosError.isError) {
      setShowPhotosErrors(true);
    }
  }, [photosError, showPhotosErrors]);
  const onClosePhotoErrors = () => {
    setShowPhotosErrors(false);
    setHasPhotosErrorsBeenClosed(true);
  };

  const handleCloseAll = async () => {
    await invalidateDonations();
    reset();
    setStep(initializedStep);
    onCloseGive();
  };

  const onNextStep = async () => {
    switch (step) {
      case 'photos':
        setStep('title&category');
        await send({ event: AnalyticsEventType.DONATION_FORM_STEP_2, donation_form_type: isCreationDonationType ? 'creation' : 'update' });
        break;
      case 'title&category':
        setStep('rest');
        await send({ event: AnalyticsEventType.DONATION_FORM_STEP_3, donation_form_type: isCreationDonationType ? 'creation' : 'update' });
        break;
      case 'rest':
        onSubmit();
        break;
      default:
        break;
    }
  };

  const onPreviousStep = async () => {
    switch (step) {
      case 'title&category':
        setStep('photos');
        break;
      case 'rest':
        setStep('title&category');
        break;
      default:
        break;
    }
  };

  const resetForm = () => {
    reset();
    setStep(initializedStep);
  };

  const redirectToDonation = () => {
    resetForm();
    onCloseGive();
    if (donationId) {
      push(new Routes.DonationRoute(donationId));
    }
  };

  const onCategoryNotFound = async (search: string) => {
    await send({ event: AnalyticsEventType.CATEGORY_OBJECT_NOT_FOUND, category_name: search });
  };

  useEffect(() => {
    const analytics = async () => send({ event: AnalyticsEventType.DONATION_FORM_STEP_1, donation_form_type: isCreationDonationType ? 'creation' : 'update' });

    analytics();
  }, []);

  const donationTitle = watch('title');
  const category = watch('category');
  const { options } = categories?.findById(category?.id) ?? { options: undefined };
  const { suggestions } = Store.suggestion.useSuggestion({ donationTitle });

  const PhotosStep: React.ReactNode = (
    <div className="flex h-full flex-col gap-5">
      <ProgressDot currentStep={step} />
      <div className="inline-flex h-full flex-col md:gap-9">
        <FormContainer>
          <div className="flex flex-col gap-5">
            <Controller
              name="photos"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.photos;
                return (
                  <div className="flex flex-col gap-2">
                    <FormLabel icon="image" label={t('form.photos.label', { ns })} htmlFor="photos" />
                    <PhotoInput id="photos" value={value} onChange={onChange} onBlur={onBlur} />
                    {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                  </div>
                );
              }}
            />

            <Info text={t('form.photos.info', { ns })} />
          </div>
        </FormContainer>

        <div className="mt-auto hidden max-w-min self-end lg:flex">
          <Button type="button" variant="primary" size="large" iconRight="arrow-right" isDisabled={errors.photos.isError || !errors.photos.isSuccess} onClick={onNextStep}>
            {t('form.photos.next', { ns })}
          </Button>
        </div>
        <div className="mt-auto flex w-full lg:hidden">
          <Button type="button" variant="primary" size="large" iconRight="arrow-right" isDisabled={errors.photos.isError || !errors.photos.isSuccess} onClick={onNextStep}>
            {t('form.photos.next', { ns })}
          </Button>
        </div>
      </div>
    </div>
  );

  const FormStep: React.ReactNode = (
    <div className="flex h-full flex-col gap-5">
      <ProgressDot currentStep={step} />
      <div className="inline-flex h-full flex-col justify-between md:gap-9">
        <FormContainer>
          <div className="flex flex-col gap-5">
            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.title;
                return (
                  <div className="flex flex-col gap-2">
                    <FormLabel icon="type" label={t('form.title&category.title.label', { ns })} htmlFor="title" />
                    <TextInput id="title" value={value} onChange={onChange} onBlur={onBlur} placeholder={t('form.title&category.title.placeholder', { ns })} />
                    {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                  </div>
                );
              }}
            />

            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.category;
                return (
                  <div className="flex flex-col gap-2">
                    <FormLabel icon="tag" label={t('form.title&category.cat.label', { ns })} htmlFor="category" />
                    <CategoryInput
                      id="cat"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder={t('form.title&category.cat.placeholder', { ns })}
                      onNotFound={onCategoryNotFound}
                      isFloating={false}
                      suggestions={suggestions}
                    />
                    {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                  </div>
                );
              }}
            />

            {options &&
              options.map(option => {
                switch (option.option) {
                  case 'state_option': {
                    const items = option.values.map(value => ({ value, text: t(`options.state_option.${value}`, { ns: 'common' }) }));
                    return (
                      <Controller
                        key={option.option}
                        control={control}
                        name="state_option"
                        rules={{ required: option.isRequired ?? false }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          const { isError, message, isSuccess } = errors.state_option;
                          return (
                            <div className="flex flex-col gap-2">
                              <FormLabel label={t('options.state_option.label', { ns: 'common' })} icon="corner-down-right" optional={!option.isRequired} htmlFor="state_option" />
                              <SelectInput
                                id="state_option"
                                items={items}
                                placeholder={t('options.state_option.placeholder', { ns: 'common' })}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value ?? null}
                              />
                              {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                            </div>
                          );
                        }}
                      />
                    );
                  }

                  case 'clothes': {
                    const items = Object.entries(groupBy(option.values, e => e.split(':')[0])).map(([parent, children]) => ({
                      text: t(`options.clothes.${parent}`, { ns: 'common' }),
                      value: parent,
                      children: children.map(child => ({ text: t(`options.clothes.${child.replace(':', '-')}`, { ns: 'common' }), value: child })),
                    }));
                    return (
                      <Controller
                        key={option.option}
                        control={control}
                        name="clothes"
                        rules={{ required: option.isRequired ?? false }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          const { isError, message, isSuccess } = errors.clothes;
                          return (
                            <div className="flex flex-col gap-2">
                              <FormLabel label={t('options.clothes.label', { ns: 'common' })} icon="corner-down-right" optional={!option.isRequired} htmlFor="clothes" />
                              <SelectInput id="clothes" items={items} placeholder={t('options.clothes.placeholder', { ns: 'common' })} onChange={onChange} onBlur={onBlur} value={value ?? null} />
                              {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                            </div>
                          );
                        }}
                      />
                    );
                  }

                  case 'shoes': {
                    const items = Object.entries(groupBy(option.values, e => e.split(':')[0])).map(([parent, children]) => ({
                      text: t(`options.shoes.${parent}`, { ns: 'common' }),
                      value: parent,
                      children: children.map(child => ({ text: t(`options.shoes.${child.replace(':', '-')}`, { ns: 'common' }), value: child })),
                    }));
                    return (
                      <Controller
                        key={option.option}
                        control={control}
                        name="shoes"
                        rules={{ required: option.isRequired ?? false }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          const { isError, message, isSuccess } = errors.shoes;
                          return (
                            <div className="flex flex-col gap-2">
                              <FormLabel icon="corner-down-right" label={t('options.shoes.label', { ns: 'common' })} optional={!option.isRequired} htmlFor="shoes" />
                              <SelectInput id="shoes" items={items} placeholder={t('options.shoes.placeholder', { ns: 'common' })} onChange={onChange} onBlur={onBlur} value={value ?? null} />
                              {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                            </div>
                          );
                        }}
                      />
                    );
                  }

                  case 'bra': {
                    const items = option.values.map(value => ({ value, text: t(`options.bra.${value}`, { ns: 'common' }) }));
                    return (
                      <Controller
                        key={option.option}
                        control={control}
                        name="bra"
                        rules={{ required: option.isRequired ?? false }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          const { isError, message, isSuccess } = errors.bra;
                          return (
                            <div className="flex flex-col gap-2">
                              <FormLabel icon="corner-down-right" label={t('options.bra.label', { ns: 'common' })} optional={!option.isRequired} htmlFor="bra" />

                              <SelectInput id="bra" items={items} placeholder={t('options.bra.placeholder', { ns: 'common' })} onChange={onChange} onBlur={onBlur} value={value ?? null} />
                              {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                            </div>
                          );
                        }}
                      />
                    );
                  }

                  case 'fashion_accessory': {
                    const items = option.values.map(value => ({ value, text: t(`options.fashion_accessory.${value}`, { ns: 'common' }) }));
                    return (
                      <Controller
                        key={option.option}
                        control={control}
                        name="fashion_accessory"
                        rules={{ required: option.isRequired ?? false }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          const { isError, message, isSuccess } = errors.fashion_accessory;
                          return (
                            <div className="flex flex-col gap-2">
                              <FormLabel icon="corner-down-right" label={t('options.fashion_accessory.label', { ns: 'common' })} optional={!option.isRequired} htmlFor="fashion_accessory" />
                              <SelectInput
                                id="fashion_accessory"
                                items={items}
                                placeholder={t('options.fashion_accessory.placeholder', { ns: 'common' })}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value ?? null}
                              />
                              {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                            </div>
                          );
                        }}
                      />
                    );
                  }

                  default:
                    return null;
                }
              })}
          </div>
        </FormContainer>

        <div className="w-full lg:w-auto lg:self-end">
          <Button
            type="button"
            size="large"
            iconRight="arrow-right"
            isDisabled={errors.title.isError || errors.category.isError || !errors.title.isSuccess || !errors.category.isSuccess}
            onClick={onNextStep}
          >
            {t('form.title&category.next', { ns })}
          </Button>
        </div>
      </div>
    </div>
  );

  const FormMoreStep: React.ReactNode = (
    <div className="flex h-full flex-col gap-5">
      <ProgressDot currentStep={step} />
      <div className="inline-flex h-full flex-col pb-7 md:gap-9">
        <FormContainer>
          <div className="flex flex-col gap-5">
            <Controller
              control={control}
              name="location"
              defaultValue={valuesFromUser?.location}
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.location;
                return (
                  <div className="flex flex-col gap-2">
                    <FormLabel icon="pin" label={t('form.rest.location.label', { ns })} htmlFor="location" />
                    <CityInput id="location" value={value} onChange={onChange} onBlur={onBlur} type="limited" />
                    {message && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                  </div>
                );
              }}
            />

            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.description;
                return (
                  <div className="flex flex-col gap-2">
                    <FormLabel icon="edit" label={t('form.rest.description.label', { ns })} optional htmlFor="description" />
                    <TextAreaInput id="description" value={value ?? ''} onChange={onChange} onBlur={onBlur} placeholder={t('form.rest.description.placeholder', { ns })} />
                    <HintMessage isError={isError} isSuccess={isSuccess} message={message} hasCharCounter inputLength={value?.length ?? 0} max={1000} />
                  </div>
                );
              }}
            />

            <Controller
              control={control}
              name="signature"
              defaultValue={valuesFromUser?.signature}
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.signature;
                return (
                  <div className="flex flex-col gap-2">
                    <FormLabel icon="edit" label={t('form.rest.signature.label', { ns })} optional htmlFor="signature" />
                    <TextAreaInput id="signature" value={value ?? ''} onChange={onChange} onBlur={onBlur} placeholder={t('form.rest.signature.placeholder', { ns })} />
                    <HintMessage isError={isError} isSuccess={isSuccess} message={message} hasCharCounter inputLength={value?.length ?? 0} max={400} />
                  </div>
                );
              }}
            />
          </div>
        </FormContainer>

        <div className="mt-auto w-full">
          <Button
            type="submit"
            size="large"
            onClick={onNextStep}
            isLoading={isLoading}
            isDisabled={errors.location.isError || errors.description.isError || errors.signature.isError || !errors.location.isSuccess}
          >
            {t('form.rest.next', { ns })}
          </Button>
        </div>
      </div>
    </div>
  );

  const ConfirmationStep: React.ReactNode = (
    <div className="flex h-full flex-col">
      <div className="inline-flex h-full flex-col justify-between md:gap-9">
        <div className="flex flex-col items-center gap-6 md:gap-5">
          <img src="/assets/illustration/confetti.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-fit" />
          <div className="flex flex-col items-center gap-3 text-center">
            <p className="text-title font-semibold text-content-secondary">{t('form.confirmation.title', { ns: 'donation.create' })}</p>
            <p className="text-body-primary font-normal text-content-primary">{t('form.confirmation.content', { ns: 'donation.create' })}</p>
          </div>
        </div>

        <div className="mt-auto flex w-full flex-col gap-6 md:gap-5">
          <Button variant="secondary" onClick={redirectToDonation}>
            {t('form.confirmation.button.seeCurrent', { ns: 'donation.create' })}
          </Button>
          <Button variant="give" iconLeft="add" onClick={resetForm}>
            {t('form.confirmation.button.give', { ns: 'donation.create' })}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex size-full flex-col bg-bg-primary">
      {step === 'photos' && (
        <Header
          content={t('nav-content', { ns })}
          iconBefore="arrow-left"
          onBefore={isCreationDonationType ? onCloseGive : back}
          iconAfter={isCreationDonationType ? 'close' : undefined}
          onAfter={isCreationDonationType ? onCloseGive : undefined}
        />
      )}
      {step === 'title&category' && (
        <Header
          content={t('nav-content', { ns })}
          iconBefore="arrow-left"
          onBefore={onPreviousStep}
          iconAfter={isCreationDonationType ? 'close' : undefined}
          onAfter={isCreationDonationType ? onCloseGive : undefined}
        />
      )}
      {step === 'rest' && (
        <Header
          content={t('nav-content', { ns })}
          iconBefore="arrow-left"
          onBefore={onPreviousStep}
          iconAfter={isCreationDonationType ? 'close' : undefined}
          onAfter={isCreationDonationType ? onCloseGive : undefined}
        />
      )}
      {step === 'published' && <Header content="" iconAfter="close" onAfter={handleCloseAll} />}

      {(showPhotosErrors || (errors.global.isError && errors.global.message)) && (
        <div className="flex-flex-col my-4 gap-2">
          {showPhotosErrors && (
            <Alert status="error" onClose={onClosePhotoErrors}>
              {t('form.photos.expired', { ns: 'donation.create' })}
            </Alert>
          )}

          {errors.global.isError && errors.global.message && (
            <Alert status="error" onClose={onCleanGlobalError}>
              {t(errors.global.message, { ns: 'common' })} {errors.global.code}
            </Alert>
          )}
        </div>
      )}

      {step === 'photos' && PhotosStep}
      {step === 'title&category' && FormStep}
      {step === 'rest' && FormMoreStep}
      {step === 'published' && ns === 'donation.create' && ConfirmationStep}
    </div>
  );
};

export default DonationForm;
