import { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';

const useOpenGive = () => {
  const { currentPage, push } = useRouterContext();

  const {
    common: { openGive },
  } = AbstractRoute.useCommonParams();

  const onOpenGive = () => {
    currentPage.updateCommon(prev => ({ ...prev, openGive: true }));
    push(currentPage);
  };

  const onCloseGive = () => {
    currentPage.updateCommon(prev => ({ ...prev, openGive: undefined }));
    push(currentPage);
  };

  return { openGive: !!openGive, onOpenGive, onCloseGive };
};

export default useOpenGive;
