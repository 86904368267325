import * as yup from 'yup';

import type { CommonSearchParams, Empty, UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute } from '@/core/lib/router/route';

const googleCallbackRouteParamsSchema = yup.object({ code: yup.string().required() });

class GoogleCallbackRoute extends AbstractRoute {
  static path = '/authentification/callback/google';

  getPath() {
    return GoogleCallbackRoute.path;
  }

  static isPrivate = false;

  private code: string;

  constructor(code: string, common?: CommonSearchParams) {
    super(common);
    this.code = code;
  }

  static useParams(): UseParamsReturn<Empty, typeof googleCallbackRouteParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const search = googleCallbackRouteParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page: {}, search, common };
  }

  static init(url: URL): GoogleCallbackRoute {
    const casted = googleCallbackRouteParamsSchema.cast({ code: url.searchParams.get('code') }, { stripUnknown: true, assert: false });

    const common = this.searchParamsToCommon(url.searchParams);

    return new GoogleCallbackRoute(casted.code, common);
  }

  isPrivate() {
    return GoogleCallbackRoute.isPrivate;
  }

  resolve() {
    return `/authentification/callback/google${this.computeParams({ code: this.code })}`;
  }

  clone(common: CommonSearchParams = {}): GoogleCallbackRoute {
    const clone = GoogleCallbackRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

const appleCallbackRouteParamsSchema = yup.object({ code: yup.string().required() });

class AppleCallbackRoute extends AbstractRoute {
  static path = '/authentification/callback/apple';

  getPath() {
    return AppleCallbackRoute.path;
  }

  static isPrivate = false;

  private code: string;

  constructor(code: string, common?: CommonSearchParams) {
    super(common);
    this.code = code;
  }

  static useParams(): UseParamsReturn<Empty, typeof appleCallbackRouteParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const search = appleCallbackRouteParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page: {}, search, common };
  }

  static init(url: URL): AppleCallbackRoute {
    const casted = appleCallbackRouteParamsSchema.cast({ code: url.searchParams.get('code') }, { stripUnknown: true, assert: false });

    const common = this.searchParamsToCommon(url.searchParams);

    return new AppleCallbackRoute(casted.code, common);
  }

  isPrivate() {
    return AppleCallbackRoute.isPrivate;
  }

  resolve() {
    return `/authentification/callback/apple${this.computeParams({ code: this.code })}`;
  }

  clone(common: CommonSearchParams = {}): AppleCallbackRoute {
    const clone = AppleCallbackRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

const facebookCallbackRouteParamsSchema = yup.object({ code: yup.string().required(), data: yup.string().required() });

class FacebookCallbackRoute extends AbstractRoute {
  static path = '/authentification/callback/facebook';

  getPath() {
    return FacebookCallbackRoute.path;
  }

  static isPrivate = false;

  private code: string;

  private data: string;

  constructor(code: string, data: string, common?: CommonSearchParams) {
    super(common);
    this.code = code;
    this.data = data;
  }

  static useParams(): UseParamsReturn<Empty, typeof facebookCallbackRouteParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const search = facebookCallbackRouteParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page: {}, search, common };
  }

  static init(url: URL): FacebookCallbackRoute {
    const casted = facebookCallbackRouteParamsSchema.cast({ code: url.searchParams.get('code'), data: url.searchParams.get('data') }, { stripUnknown: true, assert: false });

    const common = this.searchParamsToCommon(url.searchParams);

    return new FacebookCallbackRoute(casted.code, casted.data, common);
  }

  isPrivate() {
    return FacebookCallbackRoute.isPrivate;
  }

  resolve() {
    return `/authentification/callback/facebook${this.computeParams({ code: this.code, data: this.data })}`;
  }

  clone(common: CommonSearchParams = {}): FacebookCallbackRoute {
    const clone = FacebookCallbackRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

export default { GoogleCallbackRoute, AppleCallbackRoute, FacebookCallbackRoute };
