import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { CategoryDomain } from '@/core/lib/new-architecture/domain/categories.domain';
import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import Routes from '@/core/lib/router/routes';
import { type SavedSearchForm, savedSearchForm, type SavedSearchResult } from '@/core/types/favorite';

export interface SavedSearchDomainConstructor {
  data: SavedSearchResult;
  categories: CategoriesDomain;
}

interface SavedSearchParams {
  category: string | null;
  distance: number | null;
  title: string | null;
  loc: string | null;
}

interface SavedSearchSettings {
  label: string;
  email: boolean;
  push: boolean;
  createdAt: Dayjs;
  unread: number;
}

export interface SavedSearchDomainInterface {
  hash: string;

  getSettings: () => SavedSearchSettings;
  getParams: () => SavedSearchParams;
  getForm: () => SavedSearchForm;
  getRoute: () => InstanceType<typeof Routes.CatalogueRoute>;

  getUnread: () => number;
}

class SavedSearchDomain implements SavedSearchDomainInterface {
  private data: SavedSearchResult;

  private categories: CategoryDomain[];

  public hash: string;

  constructor({ data, categories }: SavedSearchDomainConstructor) {
    this.data = data;

    this.hash = data.hash;

    this.categories = data.params.cat
      ? (Array.isArray(data.params.cat) ? data.params.cat : [data.params.cat]).reduce((acc, catId) => {
          const category = categories.findById(catId);

          if (category) {
            return [...acc, category];
          }

          return acc;
        }, [] as CategoryDomain[])
      : [];
  }

  public getSettings(): SavedSearchSettings {
    return {
      label: this.data.label,
      email: this.data.email,
      push: this.data.push,
      createdAt: dayjs(this.data.created_at),
      unread: this.data.unread,
    };
  }

  public getParams(): SavedSearchParams {
    const category = this.categories.length > 0 ? this.categories[0] : null;
    return {
      category: category ? category.label : null,
      title: this.data.params.title ?? null,
      loc: this.data.params.loc ?? null,
      distance: this.data.params.distance ?? null,
    };
  }

  public getForm(): SavedSearchForm {
    const cast = savedSearchForm.cast(
      {
        ...this.data.params,
        label: this.data.label,
        email: this.data.email,
        push: this.data.push,
      },
      { stripUnknown: true, assert: false },
    );
    return cast;
  }

  public getRoute(): InstanceType<typeof Routes.CatalogueRoute> {
    const cat = this.categories.length > 0 ? this.categories[0].alias : undefined;
    // when we send 0, the API omits it for now we do it
    // TO-DO remove the hack when Mathieu changes the API
    const distance = this.data.params.distance ?? (this.data.params.loc ? 0 : undefined);

    return new Routes.CatalogueRoute({
      title: this.data.params.title ?? undefined,
      cat,
      loc: this.data.params.loc ?? undefined,
      sort: this.data.params.sort ?? undefined,
      distance,
    });
  }

  public getUnread(): number {
    return this.data.unread;
  }
}

export default SavedSearchDomain;
