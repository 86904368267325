import { useQueryClient } from '@tanstack/react-query';

import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import getToken, { getTokenNoFail } from '@/core/lib/new-architecture/query/token';
import type { Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/donation'] as const,
  donation: (id: number) => [...keys.base, id] as const,
  donationLoggedIn: (id: number, isLoggedIn: boolean) => [...keys.donation(id), isLoggedIn] as const,
  takers: (id: number) => [...keys.base, id, '/takers'] as const,
};

interface DonationParams {
  id?: number;
  isLoggedIn: boolean;
}

const useDonation: Query<Awaited<ReturnType<typeof Api.query.donation.get>>, DonationParams> = ({ data: { id, isLoggedIn }, token }) => {
  return useQuery({
    queryKey: keys.donationLoggedIn(id as number, isLoggedIn),
    queryFn: async () => Api.query.donation.get(id as number, await getTokenNoFail(token)),
    enabled: !!id,
    retry: 0,
    refetchInterval: 5000,
  });
};

useDonation.prefetch = async ({ queryClient, id, isLoggedIn }) => {
  await queryClient.prefetchQuery({
    queryKey: keys.donationLoggedIn(id as number, isLoggedIn),
    queryFn: () => Api.query.donation.get(id as number),
  });
};

interface DonationTakerParams {
  id?: number;
}

const useTakers: Query<Awaited<ReturnType<typeof Api.query.donation.takers>>, DonationTakerParams> = ({ data: { id }, token }) => {
  return useQuery({
    queryKey: keys.takers(id as number),
    queryFn: async () => Api.query.donation.takers(id as number, await getToken(token)),
    enabled: !!id,
    retry: 0,
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  const invalidateDonation = (id: number) => queryClient.invalidateQueries({ queryKey: keys.donation(id) });
  return { invalidate, invalidateDonation };
};

export default { useDonation, useTakers, useInvalidate };
