import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';

import type CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import type { DonationPersonae } from '@/core/lib/new-architecture/domain/donation.domain';
import UserDomain from '@/core/lib/new-architecture/domain/user.domain';
import computeDistance from '@/core/lib/utils/computeDistance';
import type { ConversationItemResult, MessageResult } from '@/core/types/conversation';
import { ConversationState, MessageCode, NoWriteReason } from '@/core/types/conversation';
import { DonationState, type PublicDonationResult } from '@/core/types/donation';
import type { UserResult } from '@/core/types/users';

type CommonMessage = {
  id: number;
  body?: string | null;
  participant?: Participant;
  media?: string | null;
  isDeleted?: boolean;
  date: Dayjs;
  isCurrentGiver: boolean;
};

export interface MessageMessage extends CommonMessage {
  code: MessageCode.MESSAGE;
}

export interface MessageCreated extends CommonMessage {
  code: MessageCode.CREATED;
  body: null;
  you: UserResult;
  city: string | null;
  distance: number | null;
}

export interface MessageReserved extends CommonMessage {
  code: MessageCode.RESERVED;
  body: string;
  you: UserResult;
  action: UnreserveAction | null;
}

export interface MessageReservedOther extends CommonMessage {
  code: MessageCode.RESERVED_OTHER;
  body: string;
}

export interface MessageUnreserved extends CommonMessage {
  code: MessageCode.UNRESERVED;
  body: string;
}

export interface MessageUnreservedOther extends CommonMessage {
  code: MessageCode.UNRESERVED_OTHER;
  body: string;
}

export interface MessageGiven extends CommonMessage {
  code: MessageCode.GIVEN;
  body: string;
  you: UserResult;
  co2: number;
  takerUsername: string;
  giverUsername: string;
  action: NotationAction | null;
}

export interface MessageGivenOther extends CommonMessage {
  code: MessageCode.GIVEN_OTHER;
  body: string;
  you: UserResult;
}

export interface MessageDone extends CommonMessage {
  code: MessageCode.DONE;
  body: string;
}

export interface MessageReceived extends CommonMessage {
  code: MessageCode.RECEIVED;
  body: string;
  action: NotationAction | null;
}

export interface MessageReceivedOther extends CommonMessage {
  code: MessageCode.RECEIVED_OTHER;
  body: string;
}

export interface MessageGiverBlock extends CommonMessage {
  code: MessageCode.GIVER_BLOCK;
  body: string;
  you: UserResult;
}

export interface MessageTakerBlock extends CommonMessage {
  code: MessageCode.TAKER_BLOCK;
  body: string;
  you: UserResult;
}

export interface MessageGiverUnblock extends CommonMessage {
  code: MessageCode.GIVER_UNBLOCK;
  body: string;
  you: UserResult;
}

export interface MessageTakerUnblock extends CommonMessage {
  code: MessageCode.TAKER_UNBLOCK;
  body: string;
  you: UserResult;
}

export interface MessageGiverArchived extends CommonMessage {
  code: MessageCode.GIVER_ARCHIVED;
  body: string;
}

export interface MessageTakerArchived extends CommonMessage {
  code: MessageCode.TAKER_ARCHIVED;
  body: string;
}

export type Message =
  | MessageMessage
  | MessageCreated
  | MessageReserved
  | MessageReservedOther
  | MessageUnreserved
  | MessageUnreservedOther
  | MessageGiven
  | MessageGivenOther
  | MessageDone
  | MessageReceived
  | MessageReceivedOther
  | MessageGiverBlock
  | MessageTakerBlock
  | MessageGiverUnblock
  | MessageTakerUnblock
  | MessageGiverArchived
  | MessageTakerArchived;

export enum Participant {
  ME = 'me',
  YOU = 'you',
}

export enum ConversationActionType {
  RESERVE = 'reserve',
  UNRESERVE = 'unreserve',
  GIVE = 'give',
  RECEIVE = 'receive',
  NOTATION = 'notation',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
}

export type ReserveAction = {
  action: ConversationActionType.RESERVE;
  donationId: number;
  takerUuid: string;
};

export type UnreserveAction = {
  action: ConversationActionType.UNRESERVE;
  donationId: number;
};

export type GiveAction = {
  action: ConversationActionType.GIVE;
  donationId: number;
  takerUuid: string;
  takerUsername: string;
};

export type ReceiveAction = {
  action: ConversationActionType.RECEIVE;
  donationId: number;
  takerUuid: string;
  giverUsername: string;
};

export type NotationAction = {
  action: ConversationActionType.NOTATION;
  notationId: number;
  isNice: boolean | null;
  isPunctual: boolean | null;
  username: string;
};

export type ArchiveAction = {
  action: ConversationActionType.ARCHIVE;
  conversationId: number;
};

export type UnarchiveAction = {
  action: ConversationActionType.UNARCHIVE;
  conversationId: number;
};

export type ConversationAction = ReserveAction | UnreserveAction | GiveAction | ReceiveAction | NotationAction | ArchiveAction | UnarchiveAction;

export interface ConversationActions {
  primary: ConversationAction[];
  secondary: ConversationAction[];
}

export type WriteStatus = 'ready' | 'iAmBlocked' | 'iHaveBlocked' | 'iAmDisabled' | 'otherDisabled' | 'donationState' | 'ended';

interface ConversationDomainInterface {
  isCurrentGiver: () => boolean;
  isCreate: () => boolean;
  personae: DonationPersonae;

  getId: () => number;
  getWriteStatus: () => WriteStatus;
  getUnread: () => number;
  getMessages: () => Message[];
  getActions: () => ConversationActions;
  getYou: () => UserDomain;
  getDonation: () => PublicDonationResult;
  getTakerToDonationDistance: () => number | null;
}

interface ConversationDomainConstructor {
  data: ConversationItemResult;
  messages: MessageResult[];
  current: CurrentDomain;
  you: UserResult;
  isWinner?: boolean;
}

class ConversationDomain implements ConversationDomainInterface {
  private data: ConversationItemResult;

  private messages: MessageResult[];

  private current: CurrentDomain;

  private you: UserResult;

  private isWinner: boolean;

  constructor({ data, messages, current, you, isWinner }: ConversationDomainConstructor) {
    this.data = data;
    this.current = current;
    this.you = you;
    this.messages = messages;
    this.isWinner = isWinner ?? false;
  }

  public getId(): number {
    return this.data.conversation.id;
  }

  public isCurrentGiver(): boolean {
    return this.current.uuid === this.data.donation.giver;
  }

  get personae(): DonationPersonae {
    if (!this.isWinner) return 'none';
    if (this.isCurrentGiver()) return 'giver';
    return 'taker';
  }

  public getState(): ConversationState {
    if (this.isCurrentGiver()) {
      return this.data.conversation.giver_state;
    }

    return this.data.conversation.taker_state;
  }

  public isCreate(): boolean {
    // special case when we create a conversation
    return this.data.conversation.id === 0;
  }

  public getUnread(): number {
    return this.current.uuid === this.data.conversation.giver ? this.data.conversation.giver_unread : this.data.conversation.taker_unread;
  }

  private donationCanReceiveMsg(): boolean {
    const cannot = [DonationState.INITIALIZED, DonationState.MODERATION, DonationState.REFUSED];

    if (cannot.includes(this.data.donation.state)) {
      return false;
    }

    return true;
  }

  public getWriteStatus(): WriteStatus {
    const reasons = this.data.no_write_reasons;

    if (reasons.includes(NoWriteReason.DONATION_STATE) || !this.donationCanReceiveMsg()) {
      const donationState = this.getDonation().state;
      if (donationState === 'ended') return 'ended';
      return 'donationState';
    }

    if (reasons.includes(NoWriteReason.GIVER_STATE)) {
      return this.isCurrentGiver() ? 'iAmDisabled' : 'otherDisabled';
    }

    if (reasons.includes(NoWriteReason.GIVER_BLOCK)) {
      return this.isCurrentGiver() ? 'iHaveBlocked' : 'iAmBlocked';
    }

    if (reasons.includes(NoWriteReason.TAKER_STATE)) {
      return this.isCurrentGiver() ? 'otherDisabled' : 'iAmDisabled';
    }

    if (reasons.includes(NoWriteReason.TAKER_BLOCK)) {
      return this.isCurrentGiver() ? 'iAmBlocked' : 'iHaveBlocked';
    }

    return 'ready';
  }

  private getUsernames(): { giver: string; taker: string } {
    const taker = this.isCurrentGiver() ? this.you.username : this.current.getInfo().username;
    const giver = this.isCurrentGiver() ? this.current.getInfo().username : this.you.username;

    return {
      taker,
      giver,
    };
  }

  private getUuid(): { giver: string; taker: string } {
    const taker = this.isCurrentGiver() ? this.you.uuid : this.current.uuid;
    const giver = this.isCurrentGiver() ? this.current.uuid : this.you.uuid;

    return {
      taker,
      giver,
    };
  }

  public getActions(): ConversationActions {
    const { taker: takerUuid } = this.getUuid();
    const { taker: takerUsername, giver: giverUsername } = this.getUsernames();

    const reserve = {
      action: ConversationActionType.RESERVE,
      donationId: this.data.donation.id,
      takerUuid,
    } satisfies ReserveAction;

    const give = {
      action: ConversationActionType.GIVE,
      donationId: this.data.donation.id,
      takerUuid,
      takerUsername,
    } satisfies GiveAction;

    const receive = {
      action: ConversationActionType.RECEIVE,
      donationId: this.data.donation.id,
      takerUuid,
      giverUsername,
    } satisfies ReceiveAction;

    const archive = {
      action: ConversationActionType.ARCHIVE,
      conversationId: this.getId(),
    } satisfies ArchiveAction;

    const unarchive = {
      action: ConversationActionType.UNARCHIVE,
      conversationId: this.getId(),
    } satisfies UnarchiveAction;

    const archiveOrUnarchive = this.getState() === ConversationState.ARCHIVED ? unarchive : archive;

    if (this.getWriteStatus() !== 'ready') {
      return { primary: [], secondary: [archiveOrUnarchive] };
    }

    if (this.isCurrentGiver()) {
      if (this.data.donation.state === DonationState.PUBLISHED) {
        return { primary: [reserve], secondary: [archiveOrUnarchive] };
      }

      if (this.data.donation.state === DonationState.RESERVED && this.isWinner) {
        return { primary: [give], secondary: [archiveOrUnarchive] };
      }
    }

    if (this.data.donation.state === DonationState.RESERVED && this.isWinner) {
      return { primary: [receive], secondary: [archiveOrUnarchive] };
    }

    return { primary: [], secondary: [archiveOrUnarchive] };
  }

  public getMessages(): Message[] {
    const l = this.messages.reduce((acc, message) => {
      const m = this.getMessage(message);
      if (!m) {
        return acc;
      }

      return [...acc, m];
    }, [] as Message[]);

    return l;
  }

  public getLastMessage(): Message | null {
    const [last] = orderBy(this.messages, message => dayjs(message.created_at).valueOf(), ['asc']);
    return this.getMessage(last);
  }

  public getYou(): UserDomain {
    return new UserDomain({
      data: {
        ...this.you,
        city: this.you.city ?? 0,
        cities: this.data.cities,
        admins: this.data.admins,
        countries: this.data.countries,
      },
      current: this.current,
    });
  }

  public getDonation(): PublicDonationResult {
    return this.data.donation;
  }

  private getCancelReservationAction(message: MessageResult): UnreserveAction | null {
    if (this.data.donation.state !== DonationState.RESERVED || !this.isCurrentGiver() || !this.isWinner) return null;

    const reservedSystemMessages = this.messages.filter(systemMessage => systemMessage.code === MessageCode.RESERVED);
    const [lastReservedMessage] = orderBy(reservedSystemMessages, reservedMessage => reservedMessage.id, ['desc']);

    if (lastReservedMessage.id === message.id) {
      return {
        action: ConversationActionType.UNRESERVE,
        donationId: this.data.donation.id,
      } satisfies UnreserveAction;
    }
    return null;
  }

  private getNotationAction(message: MessageResult): NotationAction | null {
    const notation = this.data.notations.find(notation => notation.message === message.id);

    if (!notation) {
      return null;
    }

    const { taker: takerUsername, giver: giverUsername } = this.getUsernames();

    return {
      action: ConversationActionType.NOTATION,
      notationId: notation.id,
      isNice: notation.is_nice ?? null,
      isPunctual: notation.is_punctual ?? null,
      username: this.isCurrentGiver() ? takerUsername : giverUsername,
    };
  }

  private getMessage(message: MessageResult): Message | null {
    const common = {
      id: message.id,
      body: message.body,
      media: message.media,
      participant: message.author === this.current.uuid ? Participant.ME : Participant.YOU,
      date: dayjs(message.created_at),
      isCurrentGiver: this.isCurrentGiver(),
    } satisfies CommonMessage;

    const key = this.isCurrentGiver() ? 'is-giver' : 'is-taker';

    const { taker: takerUsername, giver: giverUsername } = this.getUsernames();

    switch (message.code) {
      case MessageCode.MESSAGE:
        return { ...common, code: MessageCode.MESSAGE };

      case MessageCode.CREATED:
        return { ...common, code: MessageCode.CREATED, body: null, you: this.you, city: this.getDonationOrTakerCity(), distance: this.getTakerToDonationDistance() };

      case MessageCode.RESERVED:
        return { ...common, code: MessageCode.RESERVED, body: `components.messages.message-system.reserved.${key}`, you: this.you, action: this.getCancelReservationAction(message) };

      case MessageCode.RESERVED_OTHER:
        return { ...common, code: MessageCode.RESERVED_OTHER, body: `components.messages.message-system.reserved-other.${key}` };

      case MessageCode.UNRESERVED:
        return { ...common, code: MessageCode.UNRESERVED, body: `components.messages.message-system.unreserved.${key}` };

      case MessageCode.UNRESERVED_OTHER:
        return { ...common, code: MessageCode.UNRESERVED_OTHER, body: `components.messages.message-system.unreserved-other.${key}` };

      case MessageCode.GIVEN:
        return {
          ...common,
          code: MessageCode.GIVEN,
          body: `components.messages.message-system.given.${key}`,
          you: this.you,
          co2: this.data.donation.co2,
          takerUsername,
          giverUsername,
          action: this.getNotationAction(message),
        };

      case MessageCode.GIVEN_OTHER:
        return { ...common, code: MessageCode.GIVEN_OTHER, body: `components.messages.message-system.given-other.${key}`, you: this.you };

      case MessageCode.DONE:
        return { ...common, code: MessageCode.DONE, body: `components.messages.message-system.done.${key}` };

      case MessageCode.RECEIVED:
        return { ...common, code: MessageCode.RECEIVED, body: `components.messages.message-system.received.${key}`, action: this.getNotationAction(message) };

      case MessageCode.RECEIVED_OTHER:
        if (this.isCurrentGiver()) return null;
        return { ...common, code: MessageCode.RECEIVED_OTHER, body: `components.messages.message-system.received-other.${key}` };

      case MessageCode.GIVER_BLOCK:
        return { ...common, code: MessageCode.GIVER_BLOCK, body: `components.messages.message-system.giver-block.${key}`, you: this.you };

      case MessageCode.TAKER_BLOCK:
        return { ...common, code: MessageCode.TAKER_BLOCK, body: `components.messages.message-system.taker-block.${key}`, you: this.you };

      case MessageCode.GIVER_UNBLOCK:
        return { ...common, code: MessageCode.GIVER_UNBLOCK, body: `components.messages.message-system.giver-unblock.${key}`, you: this.you };

      case MessageCode.TAKER_UNBLOCK:
        return { ...common, code: MessageCode.TAKER_UNBLOCK, body: `components.messages.message-system.taker-unblock.${key}`, you: this.you };

      case MessageCode.GIVER_ARCHIVED:
        if (!this.isCurrentGiver()) return null;
        return { ...common, code: MessageCode.GIVER_ARCHIVED, body: `components.messages.message-system.giver-archived.${key}` };

      case MessageCode.TAKER_ARCHIVED:
        if (this.isCurrentGiver()) return null;
        return { ...common, code: MessageCode.TAKER_ARCHIVED, body: `components.messages.message-system.taker-archived.${key}` };

      default:
        return null;
    }
  }

  private getDonationOrTakerCity(): string | null {
    // In the "About" insert, we show Taker's city for the Giver and Donation's sub/city for the Taker
    if (this.isCurrentGiver()) {
      const takerCityId = this.data.users?.find(u => u.uuid === this.data.conversation.taker)?.city;
      const takerCity = this.data.cities?.find(c => c.id === takerCityId);
      return takerCity?.name ?? null;
    }
    const sub = this.data.subs?.find(s => s.id === this.data.donation.sub);
    const city = this.data.cities?.find(c => c.id === this.data.donation.city);

    if (sub) {
      return sub.name;
    }

    if (city) {
      const admin = this.data.admins?.find(a => a.id === city.admin);

      if (admin) {
        return `${city.name} (${admin.name})`;
      }

      return city.name;
    }

    return null;
  }

  public getTakerCityId(): number {
    return this.data.taker_city;
  }

  public getTakerToDonationDistance(): number | null {
    const takerCityResult = this.data.cities?.find(el => el.id === this.data.taker_city);

    return takerCityResult ? computeDistance(this.getDonation().loc, takerCityResult.loc) : null;
  }
}

export default ConversationDomain;
