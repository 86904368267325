import Bugsnag from '@bugsnag/js';

const start = () => {
  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE

  if (process.env.NEXT_PHASE !== 'phase-production-build') {
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY ?? process.env.REACT_APP_BUGSNAG_KEY ?? '',
      appVersion: process.env.NEXT_PUBLIC_CI_COMMIT_SHA ?? process.env.REACT_APP_CI_COMMIT_SHA ?? 'local',
      plugins: [],
      releaseStage: process.env.NEXT_PUBLIC_ENV ?? process.env.REACT_APP_ENV ?? 'development',
      enabledReleaseStages: ['production', 'preprod'],
      onError: event => {
        const ignorePatterns = [
          /Minified React error/,
          /__tcfapi/,
          /NotFoundError Failed to execute 'insertBefore' on 'Node'/,
          /undefined is not an object (evaluating 'n.length')/,
          /undefined is not an object (evaluating 'n.offerId')/,
          /Can't find variable: _AutofillCallbackHandler/,
          /Cannot read properties of null (reading 'postMessage')/,
          /_avast_submit/,
          /solana|ethereum/,
          /(evaluating 'a.L') /,
          /TypeError undefined is not an object (evaluating 'response.reputation') /,
          /cmp.inmobi.com/,
          /richaudience.com/,
          /pagead2.googlesyndication.com/,
        ];
        if (ignorePatterns.some(re => re.test(event.errors[0].errorMessage))) return false;
        return true;
      },
    });
  }
};

export default start;
