import React from 'react';

import DonationForm from '@/core/components/forms/DonationForm';
import Modal from '@/core/components/Modal';
import useOpenGive from '@/core/hooks/useOpenGive';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';

const CreateDonation: PageWithLayoutsAndProviders = () => {
  const { openGive, onCloseGive } = useOpenGive();
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) return null;

  return (
    <Modal isOpen={openGive} onClose={onCloseGive}>
      <DonationForm />
    </Modal>
  );
};

export default CreateDonation;
