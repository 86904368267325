import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Law: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M11.6666 35H28.3333M20 5V35M4.99998 11.6667H8.33331C11.6666 11.6667 16.6666 10 20 8.33333C23.3333 10 28.3333 11.6667 31.6666 11.6667H35M26.6666 26.6667L31.6666 13.3333L36.6666 26.6667C35.2166 27.75 33.4666 28.3333 31.6666 28.3333C29.8666 28.3333 28.1166 27.75 26.6666 26.6667ZM3.33331 26.6667L8.33331 13.3333L13.3333 26.6667C11.8833 27.75 10.1333 28.3333 8.33331 28.3333C6.53331 28.3333 4.78331 27.75 3.33331 26.6667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Law;
