import useMutation from '@/core/hooks/api/useMutation';
import { type APIError, ErrorKind } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import type { DonationDeleteReason } from '@/core/lib/new-architecture/api/donation.api';
import type ConversationDomain from '@/core/lib/new-architecture/domain/conversation.domain';
import Query from '@/core/lib/new-architecture/query';
import getToken from '@/core/lib/new-architecture/query/token';
import Store from '@/core/lib/new-architecture/store';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { DonationsNotationForm } from '@/core/types/donation';

interface ReserveParams {
  donationId: number;
  takerUuid: string;
}

const useReserve = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateDonation } = Query.donation.useInvalidate();
  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();
  const { invalidate: invalidateUserDonations } = Query.user.donations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.donation.reserve>>, APIError, ReserveParams>({
    mutationFn: async (data: ReserveParams) => Api.mutation.donation.reserve(data, await getToken(requestToken)),
    onSuccess: () => {
      invalidateDonation();
      invalidateDonations();
      invalidateConversation();
      invalidateConversations();
      invalidateUserDonations();
    },
  });

  const onReserve = async (data: ReserveParams) => {
    try {
      await mutation.mutateAsync(data);
    } catch (err) {
      const error = err as APIError;
      if (error.kind === ErrorKind.Forbidden) {
        await invalidateConversation();
      }

      throw err;
    }
  };

  return { onReserve, isLoading: mutation.isPending };
};

const useUnreserve = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateDonation } = Query.donation.useInvalidate();
  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();
  const { invalidate: invalidateUserDonations } = Query.user.donations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.donation.unreserve>>, APIError, number>({
    mutationFn: async (donationId: number) => Api.mutation.donation.unreserve(donationId, await getToken(requestToken)),
    onSuccess: async () => {
      await Promise.all([invalidateDonation(), invalidateDonations(), invalidateConversation(), invalidateConversations(), invalidateUserDonations()]);
    },
  });

  const onUnreserve = async (donationId: number) => {
    try {
      await mutation.mutateAsync(donationId);
    } catch (err) {
      const error = err as APIError;
      if (error.kind === ErrorKind.Forbidden) {
        await invalidateConversation();
      }

      throw err;
    }
  };

  return { onUnreserve, isLoading: mutation.isPending };
};

interface GiveParams {
  donationId: number;
  reason: DonationDeleteReason;
  taker?: string;
}

const useTerminate = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateUser } = Query.user.useInvalidate();
  const { invalidate: invalidateDonation } = Query.donation.useInvalidate();
  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();
  const { invalidate: invalidateUserDonations } = Query.user.donations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.donation.terminate>>, APIError, GiveParams>({
    mutationFn: async (data: GiveParams) => Api.mutation.donation.terminate(data.donationId, { taker: data.taker, reason: data.reason }, await getToken(requestToken)),
    onSuccess: async () => {
      await Promise.all([invalidateUser(), invalidateDonation(), invalidateDonations(), invalidateConversation(), invalidateConversations(), invalidateUserDonations()]);
    },
  });

  const onTerminate = async (data: GiveParams) => mutation.mutateAsync(data);

  return { onTerminate, isLoading: mutation.isPending };
};

interface SendOrCreateParams {
  message?: string;
}

const useSendOrCreate = (conversation: ConversationDomain) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateDonation } = Query.donation.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();
  const { replace } = useRouterContext();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.conversation.send>>, APIError, SendOrCreateParams>({
    mutationFn: async (data: SendOrCreateParams) => {
      const donation = conversation.getDonation();
      const isCreate = conversation.isCreate();

      if (isCreate) {
        return Api.mutation.conversation.create(donation.id, data, await getToken(requestToken));
      }

      return Api.mutation.conversation.send(conversation.getId(), data, await getToken(requestToken));
    },
    onSuccess: async res => {
      await Promise.all([invalidateDonation(), invalidateConversation(), invalidateConversations()]);
      replace(new Routes.ConversationRoute(res.conversation.id));
    },
  });

  const onSendOrCreate = async (data: SendOrCreateParams) => {
    try {
      await mutation.mutateAsync(data);
    } catch (err) {
      const error = err as APIError;
      if (error.kind === ErrorKind.Forbidden) {
        await invalidateConversation();
      }

      throw err;
    }
  };

  return { onSendOrCreate, isLoading: mutation.isPending };
};

interface NotationParams extends DonationsNotationForm {
  notationId: number;
}

const useNotation = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateUser } = Query.user.useInvalidate();
  const { invalidate: invalidateDonation } = Query.donation.useInvalidate();
  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();
  const { invalidate: invalidateUserDonations } = Query.user.donations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.donation.notation>>, APIError, NotationParams>({
    mutationFn: async (data: NotationParams) => Api.mutation.donation.notation(data.notationId, { is_nice: data.is_nice, is_punctual: data.is_punctual }, await getToken(requestToken)),
    onSuccess: async () => {
      await Promise.all([invalidateUser(), invalidateDonation(), invalidateDonations(), invalidateConversation(), invalidateConversations(), invalidateUserDonations()]);
    },
  });

  const onNotation = async (data: NotationParams) => mutation.mutateAsync(data);

  return { onNotation, isLoading: mutation.isPending };
};

export { useNotation, useReserve, useSendOrCreate, useTerminate, useUnreserve };
