import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { BreadcrumbList, ListItem, Product, WebPage, WithContext } from 'schema-dts';

import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import type CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import UserDomain from '@/core/lib/new-architecture/domain/user.domain';
import type { TFunction } from '@/core/lib/translation/translation.context';
import computeDistance from '@/core/lib/utils/computeDistance';
import { DonationState, type PrivateDonationItemResult, type PublicDonationItemResult } from '@/core/types/donation';
import type { SeoMeta } from '@/core/types/meta';
import type { UserResult, UsersResult } from '@/core/types/users';

export enum DonationActionType {
  CONVERSATIONS = 'conversations',
  FINISH = 'finish',
  SHARE = 'share',
  CONTACT = 'contact',
  FAVORITE = 'favorite',
  UPDATE = 'update',
  REPORT = 'report',
}

export type ConversationsAction = {
  action: DonationActionType.CONVERSATIONS;
  text: string;
  donationId: number;
  sollicitations: number;
};

export type ContactAction = {
  action: DonationActionType.CONTACT;
  text: string;
  conversationId: number | null;
  canCreate: boolean;
  canCreateReason: string | null;
  donationId: number;
  isBlocked: boolean;
};

export type FinishAction = {
  action: DonationActionType.FINISH;
  text: string;
  donationId: number;
  isDisabled: boolean;
};

export type FavoriteAction = {
  action: DonationActionType.FAVORITE;
  text: string;
  donationId: number;
};

export type ShareAction = {
  action: DonationActionType.SHARE;
  text: string;
  donationId: number;
  donationTitle: string;
  isDisabled: boolean;
};

export type ReportAction = {
  action: DonationActionType.REPORT;
  text: string;
  donationId: number;
  isDisabled: boolean;
};

export type UpdateAction = {
  action: DonationActionType.UPDATE;
  text: string;
  donationId: number;
  isDisabled: boolean;
};

export type DonationAction = ConversationsAction | ContactAction | FinishAction | FavoriteAction | ShareAction | ReportAction | UpdateAction;

export interface DonationActions {
  primary: DonationAction[];
  secondary: DonationAction[];
}

export interface DonationDomainConstructor {
  data: PublicDonationItemResult | PrivateDonationItemResult;
  categories: CategoriesDomain;
  current: CurrentDomain | null;
}
export type DonationPersonae = 'taker' | 'giver' | 'none';

type PhotoSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
type PhotoExt = 'webp' | 'png' | 'jpg';

export interface DonationDomainInterface {
  personae: DonationPersonae;

  category: DonationCategory | null;

  getId: () => number;
  getTitle: () => string;
  getDescription: () => string | null;
  getFirstPhoto: (size: PhotoSize, ext: PhotoExt) => string;
  getPhotos: (size: PhotoSize, ext: PhotoExt) => string[];
  getCityLabel: () => string | null;
  getDistance: () => number | null;
  getLastPublishedAt: () => Dayjs;
  getState: () => DonationState;

  getUserResult: () => UserResult;
  getUser: () => UserDomain;
  getConversationId: () => number | null;

  isBlocked: () => boolean;
  isInModeration: () => boolean;
  getActions: () => DonationActions;

  getSeo: () => SeoMeta;
  getProductSchema: () => WithContext<Product>;
  getWebpageSchema: () => WithContext<WebPage>;
  getBreadcrumbListSchema: (t: TFunction) => WithContext<BreadcrumbList>;

  computeDonationDistance: (point: { lat: number; lon: number }) => void;
}

export interface DonationCategory {
  id: number;
  alias: string;
  label: string;
  parent: DonationCategory | null;
}

const extractCategory = (id: number, categories: CategoriesDomain): DonationCategory | null => {
  const cat = categories.map.get(id);

  if (!cat) return null;

  let parent = null;

  if (cat.parent) {
    const parentCat = extractCategory(cat.parent, categories);

    if (parentCat) {
      parent = parentCat;
    }
  }

  return {
    id: cat.id,
    alias: cat.alias,
    label: cat.label,
    parent,
  };
};

class DonationDomain implements DonationDomainInterface {
  public data: PublicDonationItemResult | PrivateDonationItemResult;

  public category: DonationCategory | null;

  private current: CurrentDomain | null;

  private distance: number | null = null;

  // temporary
  public publicDonation: PublicDonationItemResult | undefined = undefined;

  public privateDonation: PrivateDonationItemResult | undefined = undefined;

  constructor({ data, categories, current }: DonationDomainConstructor) {
    this.data = data;
    this.current = current;

    this.category = extractCategory(this.data.donation.cat, categories);

    // temporary
    this.publicDonation = 'is_winner' in this.data ? this.data : undefined;
    this.privateDonation = 'is_winner' in this.data ? undefined : this.data;
  }

  get personae(): DonationPersonae {
    if (this.data.donation.giver === this.current?.uuid) return 'giver';

    if ('is_winner' in this.data && this.data.is_winner) {
      return 'taker';
    }
    return 'none';
  }

  public getId(): number {
    return this.data.donation.id;
  }

  public getTitle(): string {
    return this.data.donation.title;
  }

  public getDescription(): string | null {
    return this.data.donation.description ?? null;
  }

  public getPhotos(size: PhotoSize = 3, ext: PhotoExt = 'jpg'): string[] {
    return this.data.donation.photos.map(photo => photo.replace('{{size}}', size.toString()).replace('{{ext}}', ext));
  }

  public getFirstPhoto(size: PhotoSize = 3, ext: PhotoExt = 'jpg'): string {
    return this.data.donation.photos[0].replace('{{size}}', size.toString()).replace('{{ext}}', ext);
  }

  public getCityLabel(): string | null {
    const sub = this.data.subs?.find(s => s.id === this.data.donation.sub);
    const city = this.data.cities?.find(c => c.id === this.data.donation.city);

    if (sub) {
      return sub.name;
    }

    if (city) {
      const admin = this.data.admins?.find(a => a.id === city.admin);

      if (admin) {
        return `${city.name} (${admin.name})`;
      }

      return city.name;
    }

    return null;
  }

  public getDistance(): number | null {
    return this.distance;
  }

  public getLastPublishedAt(): Dayjs {
    return dayjs(this.data.donation.last_published_at);
  }

  public getState(): DonationState {
    return this.data.donation.state;
  }

  public getUserResult() {
    return this.data.users?.find(user => user.uuid === this.data.donation.giver) as UserResult;
  }

  public getUser(): UserDomain {
    const user = this.data.users?.find(user => user.uuid === this.data.donation.giver) as UserResult;
    return new UserDomain({
      data: {
        ...user,
        city: user.city ?? 0,
        cities: this.data.cities,
        admins: this.data.admins,
        countries: this.data.countries,
      },
      current: this.current,
    });
  }

  public getConversationId(): number | null {
    return this.publicDonation?.conversation ?? null;
  }

  public isBlocked(): boolean {
    return !!this.current?.hasBlocked(this.data.donation.giver);
  }

  public isInModeration(): boolean {
    return [DonationState.INITIALIZED, DonationState.MODERATION].includes(this.getState());
  }

  private isLoggedIn(): boolean {
    return !!this.current;
  }

  public getActions(): DonationActions {
    const donationId = this.getId();
    const donationTitle = this.getTitle();

    const conversations = {
      action: DonationActionType.CONVERSATIONS,
      text: 'components.donation.details.actions.see-messages',
      donationId,
      sollicitations: this.personae === 'giver' ? (this.data.donation.sollicitations ?? 0) : 0,
    } satisfies ConversationsAction;

    const contact = {
      action: DonationActionType.CONTACT,
      text: 'components.donation.details.actions.contact-giver',
      conversationId: 'is_winner' in this.data ? (this.data?.conversation ?? null) : null,
      canCreate: 'is_winner' in this.data ? this.data?.can_create_conversation : false,
      canCreateReason: 'is_winner' in this.data ? (this.data?.can_create_conversation_reason ?? null) : null,
      donationId,
      isBlocked: this.isBlocked(),
    } satisfies ContactAction;

    const finish = {
      action: DonationActionType.FINISH,
      text: 'components.donation.details.actions.finish-donation',
      donationId,
      isDisabled: false,
    } satisfies FinishAction;

    const favorite = {
      action: DonationActionType.FAVORITE,
      text: 'components.donation.details.actions.to-favorites',
      donationId,
    } satisfies FavoriteAction;

    const share = {
      action: DonationActionType.SHARE,
      text: this.personae === 'giver' ? 'components.donation.details.actions.share-donation' : 'components.donation.details.actions.share-ad',
      donationId,
      donationTitle,
      isDisabled: false,
    } satisfies ShareAction;

    const report = {
      action: DonationActionType.REPORT,
      text: 'components.donation.details.actions.report-donation',
      donationId,
      isDisabled: false,
    } satisfies ReportAction;

    const update = {
      action: DonationActionType.UPDATE,
      text: 'components.donation.details.actions.update-donation',
      donationId,
      isDisabled: false,
    } satisfies UpdateAction;

    switch (this.data.donation.state) {
      case DonationState.RESENT:
      case DonationState.PUBLISHED: {
        if (this.personae === 'giver') {
          const primary = [finish, conversations];
          const secondary = [share, update];
          return { primary, secondary };
        }

        const primary = [contact, favorite];
        const secondary = this.isLoggedIn() ? [share, report] : [share];

        return { primary, secondary };
      }

      case DonationState.MODERATION:
      case DonationState.INITIALIZED: {
        if (this.personae === 'giver') {
          const primary = [
            { ...conversations, isDisabled: true },
            { ...finish, isDisabled: true },
          ];
          const secondary = [
            { ...share, isDisabled: true },
            { ...update, isDisabled: true },
          ];
          return { primary, secondary };
        }

        const secondary = this.isLoggedIn()
          ? [
              { ...share, isDisabled: true },
              { ...report, isDisabled: true },
            ]
          : [{ ...share, isDisabled: true }];

        return { primary: [], secondary };
      }

      case DonationState.RESERVED: {
        if (this.personae === 'giver') {
          const primary = [finish, conversations];
          const secondary = [share, update];
          return { primary, secondary };
        }

        if (this.personae === 'taker') {
          const primary = [contact, favorite];
          const secondary = [share, report];
          return { primary, secondary };
        }

        const primary = this.isLoggedIn() ? [favorite] : [];
        const secondary = this.isLoggedIn() ? [share, report] : [share];

        return { primary, secondary };
      }

      case DonationState.ENDED: {
        const secondaryLoggedIn = [
          { ...share, isDisabled: true },
          { ...update, isDisabled: true },
        ];

        const primaryLoggedIn = [
          { ...finish, isDisabled: true },
          { ...conversations, isDisabled: true },
        ];

        if (this.personae === 'giver') {
          return { primary: primaryLoggedIn, secondary: secondaryLoggedIn };
        }

        if (this.personae === 'taker') {
          const primary = [contact, favorite];
          return { primary, secondary: secondaryLoggedIn };
        }

        const primary = this.isLoggedIn() ? [favorite] : [];
        const secondary = this.isLoggedIn() ? secondaryLoggedIn : [{ ...share, isDisabled: true }];

        return { primary, secondary };
      }

      default:
        return { primary: [], secondary: [] };
    }
  }

  public getSeo(): SeoMeta {
    return {
      title: this.data.donation.title,
      description: `${this.data.donation.description ?? this.data.donation.title} - ${this.category?.label}`,
      image: this.data.donation.photos[0].replace('{{size}}', '3').replace('{{ext}}', 'png'),
    };
  }

  public getProductSchema(): WithContext<Product> {
    const { cities } = this.data;
    const city = cities && cities[0] ? cities[0].name : undefined;

    const { admins } = this.data;
    const admin = admins && admins[0] ? admins[0].name : undefined;

    const { countries } = this.data;
    const { country, countryId } = countries && countries[0] ? { country: countries[0].name, countryId: countries[0].id } : { country: undefined, countryId: undefined };

    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: this.getTitle(),
      description: this.getDescription() ?? `${this.getTitle()} à ${this.getCityLabel()}`,
      image: this.getPhotos(),
      sku: this.getId().toString(),
      brand: {
        '@type': 'Brand',
        name: 'Donnons',
      },
      offers: {
        '@type': 'Offer',
        url: `https://donnons.org/don/${this.getId()}`,
        category: this.category?.label,
        availabilityStarts: dayjs().format('YYYY-MM-DD'),
        price: '0',
        priceCurrency: 'EUR',
        itemCondition: 'https://schema.org/UsedCondition',
        availability: 'https://schema.org/LimitedAvailability',
        priceValidUntil: dayjs().add(1, 'year').format('YYYY-MM-DD'),
        hasMerchantReturnPolicy: {
          '@type': 'MerchantReturnPolicy',
          returnPolicyCategory: 'https://schema.org/MerchantReturnNotPermitted',
          applicableCountry: countryId,
        },
        availableAtOrFrom: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressCountry: country,
            addressLocality: city,
            addressRegion: admin,
          },
        },
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          doesNotShip: true,
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: country,
          },
        },
      },
    } satisfies WithContext<Product>;
  }

  public getWebpageSchema(): WithContext<WebPage> {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: this.getTitle(),
      description: this.getDescription() ?? this.getTitle(),
      about: "Site de don d'objets",
    } satisfies WithContext<WebPage>;
  }

  public getBreadcrumbListSchema(t: TFunction): WithContext<BreadcrumbList> {
    const homeItem = {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}`,
        name: t('schema.breadcrumb.home', { ns: 'common' }),
      },
    } satisfies ListItem;
    const categoryItem = {
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}/catalogue/categorie/${this.category?.id}`,
        name: this.category?.label,
      },
    } satisfies ListItem;
    const donationItem = {
      '@type': 'ListItem',
      position: this.category ? 3 : 2,
      item: {
        '@id': `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}/don/${this.getId()}`,
        name: this.getTitle(),
      },
    } satisfies ListItem;

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: this.category ? [homeItem, categoryItem, donationItem] : [homeItem, donationItem],
    };
  }

  public getSolicitations(): number | null {
    return this.data.donation.sollicitations ?? null;
  }

  public computeDonationDistance({ lat: lat1, lon: lon1 }: { lat: number; lon: number }): void {
    const lat2 = this.data.donation.loc.lat;
    const lon2 = this.data.donation.loc.lon;
    this.distance = computeDistance({ lat: lat1, lon: lon1 }, { lat: lat2, lon: lon2 });
  }
}

export interface DonationTakersDomainInterface {
  getTakers: () => UserResult[];
}

export interface DonationTakersDomainConstructor {
  data: UsersResult;
}

export class DonationTakersDomain implements DonationTakersDomainInterface {
  private data: UsersResult;

  constructor({ data }: DonationTakersDomainConstructor) {
    this.data = data;
  }

  public getTakers() {
    return this.data.users ?? [];
  }
}

export default DonationDomain;
