import React, { PropsWithChildren, useCallback, useEffect, useId, useRef } from 'react';

import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import { useComponentsContext } from '@/core/lib/components/components.context';
import { useNativeContext } from '@/core/lib/native/native.context';

type ModalType = 'tunnel' | 'popup';

const defaultModalType: ModalType = 'tunnel';

export interface ModalProps extends React.PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  closeOnEscape?: boolean;
  type?: ModalType;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, closeOnEscape = true, type = defaultModalType }) => {
  const { useBackButton } = useComponentsContext();

  const ref = useRef<HTMLDivElement>(null);
  const previousRef = useRef<Element | null>(null);
  const { isNative } = useNativeContext();

  useKeyDownHandler([], 'Escape', (event: KeyboardEvent) => {
    event.preventDefault();

    const highestOrder = Array.from(document.querySelectorAll('div[data-dialog-role="overlay"]'))
      .sort((overlayA, overlayB) => {
        const orderA = Number(overlayA.getAttribute('data-dialog-order'));
        const orderB = Number(overlayB.getAttribute('data-dialog-order'));
        return orderB - orderA;
      })
      .map(o => o.getAttribute('data-dialog-order'))[0];

    if (closeOnEscape && ref.current?.getAttribute('data-dialog-order') === highestOrder) {
      onClose();
    }
  });

  const id = useId();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      // at mount time when isOpen is false, the overlays do not include the current modal
      const overlays = document.querySelectorAll('div[data-dialog-role="overlay"]');
      const selfOverlay = Array.from(overlays).find(overlay => overlay.attributes.getNamedItem('data-dialog-id')?.value === id);
      const overlaysLength = overlays.length;

      const previousOverlay = Array.from(overlays).find(overlay => overlay.attributes.getNamedItem('data-dialog-order')?.value === (overlaysLength - 1).toString());

      if (isOpen) {
        // when we mount it we give it the order to control which one is the most recent
        selfOverlay?.setAttribute('data-dialog-order', overlaysLength.toString());

        previousOverlay?.setAttribute('style', 'overflow: hidden');
        // we keep in a ref the previous modal to be sure to unlock it
        previousRef.current = previousOverlay ?? null;
      } else {
        previousRef.current?.setAttribute('style', '');
      }

      // if there is an overlay, lock the scroll otherwise unlock it
      if (overlaysLength > 0) {
        document.body.setAttribute('style', `position: fixed; top: -${window.scrollY}px; left: 0; right: 0`);
      } else {
        document.body.setAttribute('style', '');
      }
    }

    return () => {
      const overlays = document.querySelectorAll('div[data-dialog-role="overlay"]');

      if (overlays.length === 0) {
        document.body.setAttribute('style', '');
      }
    };
  }, [isOpen]);

  const handleBackButton = useCallback(() => {
    const overlays = document.querySelectorAll('div[data-dialog-role="overlay"]');
    const selfOverlay = Array.from(overlays).find(overlay => overlay.attributes.getNamedItem('data-dialog-id')?.value === id);
    const isFrontOverlay = overlays.length.toString() === selfOverlay?.attributes.getNamedItem('data-dialog-order')?.value;

    if (isOpen && isFrontOverlay) {
      onClose();
    }
  }, [id, isOpen]);

  useBackButton(handleBackButton);

  if (!isOpen) {
    return null;
  }

  switch (type) {
    case 'tunnel':
      return (
        <Tunnel innerRef={ref} id={id}>
          {children}
        </Tunnel>
      );
    case 'popup':
    default:
      return (
        <Popup innerRef={ref} id={id} isNative={isNative}>
          {children}
        </Popup>
      );
  }
};

const Tunnel: React.FC<PropsWithChildren<{ innerRef: React.RefObject<HTMLDivElement>; id: string }>> = ({ innerRef, id, children }) => {
  return (
    <div
      ref={innerRef}
      data-dialog-id={id}
      className={`${[
        'fixed right-0 top-0 z-50 !m-0 grid h-screen w-screen justify-items-center overflow-auto bg-bg-dark/60 focus-visible:border-none focus-visible:outline-none',
        'lg:items-start lg:p-4 lg:pt-header-desktop',
      ].join(' ')}`}
    >
      <div role="dialog" className={`w-full bg-bg-primary p-4 lg:max-w-[800px] lg:rounded-3`}>
        {children}
      </div>
    </div>
  );
};

const Popup: React.FC<PropsWithChildren<{ innerRef: React.RefObject<HTMLDivElement>; id: string; isNative: boolean }>> = ({ innerRef, id, children, isNative }) => {
  return (
    <div
      ref={innerRef}
      data-dialog-id={id}
      className={`${[
        'fixed right-0 top-0 z-50 !m-0 grid h-screen w-screen justify-items-center overflow-auto bg-bg-dark/60 p-4 focus-visible:border-none focus-visible:outline-none',
        isNative ? 'items-center' : 'items-start pt-header-desktop',
      ].join(' ')}`}
    >
      <div role="dialog" className={`w-full max-w-100 rounded-3 bg-bg-primary p-4`}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
