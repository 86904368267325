import type { InfiniteData } from '@tanstack/react-query';
import type { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

import type { CatalogueSearchSchema } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { locResultToAlias, locResultToString } from '@/core/lib/geolocation/formatter';
import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import type CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import type { AbstractRoute } from '@/core/lib/router/route';
import Routes from '@/core/lib/router/routes';
import type { TFunction } from '@/core/lib/translation/translation.context';
import type { DonationsPublicDonationListResult, DonationsPublicDonationQuery } from '@/core/types/donation';
import type { AdminResult, CityResult, CountryResult, GeoDetailResult, SubResult } from '@/core/types/geo';
import type { SeoMeta } from '@/core/types/meta';
import type { UserResult } from '@/core/types/users';

export interface CatalogueDomainConstructor {
  data: InfiniteData<DonationsPublicDonationListResult>;
  categories: CategoriesDomain;
  current: CurrentDomain | null;
  query: DonationsPublicDonationQuery;
  loc?: GeoDetailResult;
}

export interface CatalogueDomainInterface {
  hash: string;
  donations: DonationDomain[];
  search: CatalogueSearchSchema;
  query: DonationsPublicDonationQuery;
  seo: SeoMeta;

  getRoute: () => AbstractRoute;
  getBreadcrumbListSchema: (t: TFunction) => WithContext<BreadcrumbList>;
}

class CatalogueDomain implements CatalogueDomainInterface {
  public hash: string;

  public donations: DonationDomain[];

  public search: CatalogueSearchSchema;

  public seo: SeoMeta;

  public query: DonationsPublicDonationQuery;

  private defineSeoMeta() {
    const { loc, cat, title } = this.search;
    const defaultMetaTitle = 'Site de don d’objets';
    const defaultMetaDescription = "Donnez facilement une seconde vie à vos objets ! Des milliers d'annonces disponibles chaque jour pour récupérer gratuitement des objets à côté de chez vous";
    const image = '/assets/sigle.png';
    const getArticleDependingOnCategoryLabel = (categoryLabel: string): 'de' | "d'" => {
      const firstLetterOfCategoryLabelInLowerCase = categoryLabel.toLowerCase().split('')[0];
      const vowelAndH = ['a', 'e', 'i', 'o', 'u', 'y', 'h'];
      if (vowelAndH.includes(firstLetterOfCategoryLabelInLowerCase)) {
        return "d'";
      }
      return 'de';
    };
    const defineSeoMetaWithTreeSearchParams = (): SeoMeta | null => {
      if (loc && cat && title) {
        return {
          title: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label}-${title}-${loc.label}`,
          description: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label} à ${loc.label} et alentours. Récupérez-les gratuitement`,
          image,
          canonical: `?title=${title}`,
        };
      }
      return null;
    };
    const defineSeoMetaWithTwoSearchParams = (): SeoMeta | null => {
      if (loc && cat && !title) {
        return {
          title: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label}-${loc.label}`,
          description: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label} à ${loc.label}. Récupérez-les gratuitement`,
          image,
          canonical: ``,
        };
      }
      if (loc && title && !cat) {
        return {
          title: `Dons-${title}-${loc.label}`,
          description: `Dons d'objets à ${loc.label} et alentours. Récupérez-les gratuitements`,
          image,
          canonical: `?title=${title}`,
        };
      }
      if (title && cat && !loc) {
        return {
          title: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label}-${title}`,
          description: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label}. Récupérez-les gratuitements`,
          image,
          canonical: `?title=${title}`,
        };
      }
      return null;
    };
    const defineSeoMetaWithOneSearchParams = (): SeoMeta | null => {
      if (loc && !cat && !title) {
        return {
          title: `Dons-${loc.label}`,
          description: `Dons d'objets à ${loc.label} et alentours. Récupérez-les gratuitement`,
          image,
          canonical: ``,
        };
      }
      if (cat && !loc && !title) {
        return {
          title: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label}`,
          description: `Dons ${getArticleDependingOnCategoryLabel(cat.label)} ${cat.label} partout en France. Récupérez-les gratuitements`,
          image,
          canonical: '',
        };
      }
      if (title && !cat && !loc) {
        return {
          title: `Dons-${title}`,
          description: "Dons d'objets partout en France. Récupérez-les gratuitements",
          image,
          canonical: `?title=${title}`,
        };
      }
      return null;
    };
    const seoWithTreeSearchParams = defineSeoMetaWithTreeSearchParams();
    if (seoWithTreeSearchParams) {
      return seoWithTreeSearchParams;
    }
    const seoMetaWithTwoSearchParams = defineSeoMetaWithTwoSearchParams();
    if (seoMetaWithTwoSearchParams) {
      return seoMetaWithTwoSearchParams;
    }
    const seoMetaWithOneSearchParams = defineSeoMetaWithOneSearchParams();
    if (seoMetaWithOneSearchParams) {
      return seoMetaWithOneSearchParams;
    }

    return { title: defaultMetaTitle, description: defaultMetaDescription, image };
  }

  constructor({ data, categories, current, query, loc }: CatalogueDomainConstructor) {
    this.hash = data.pages[0].hash as string;
    this.query = query;

    const { admins, cities, countries, subs, users } = data.pages
      .flatMap(page => page)
      .reduce(
        (acc, page) => ({
          admins: [...acc.admins, ...(page.admins ?? [])],
          cities: [...acc.cities, ...(page.cities ?? [])],
          countries: [...acc.countries, ...(page.countries ?? [])],
          subs: [...acc.subs, ...(page.subs ?? [])],
          users: [...acc.users, ...(page.users ?? [])],
        }),
        {
          admins: [] as AdminResult[],
          cities: [] as CityResult[],
          countries: [] as CountryResult[],
          subs: [] as SubResult[],
          users: [] as UserResult[],
        },
      );

    this.donations = data.pages.flatMap(page =>
      page.donations.map(donation => {
        const donationDomain = new DonationDomain({
          data: {
            donation,
            admins,
            cities,
            countries,
            subs,
            // TO-DO remove that it should be filtered by the domain
            users: users.filter(u => u.uuid === donation.giver),
            is_winner: false,
            conversation: null,
            new_messages: 0,
            can_create_conversation: false,
            options: [],
          },
          categories,
          current,
        });

        if (loc?.result.location) {
          const { lat, lon } = loc.result.location;
          donationDomain.computeDonationDistance({ lat, lon });
        }

        return donationDomain;
      }),
    );

    const search = {
      loc: null,
      cat: null,
      title: null,
    } as CatalogueSearchSchema;

    if (query.title) {
      search.title = query.title;
    }

    if (query.cat && categories) {
      const category = categories.findByAlias(query.cat);
      search.cat = category;
    }

    if (query.loc && loc) {
      search.loc = loc
        ? {
            loc: locResultToAlias(loc.result),
            lat: loc.result.location?.lat,
            lon: loc.result.location?.lon,
            distance: Number.isNaN(Number(query.distance)) ? 0 : Number(query.distance),
            label: locResultToString(loc.result),
            refId: loc.result?.city?.ref_type === 'INSEE' ? (loc.result?.city?.ref_id ?? undefined) : undefined,
          }
        : null;
    }

    this.search = search;
    this.seo = this.defineSeoMeta();
  }

  public getRoute(): AbstractRoute {
    return new Routes.CatalogueRoute(this.query);
  }

  public getBreadcrumbListSchema(t: TFunction): WithContext<BreadcrumbList> {
    const homeItem = {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}`,
        name: t('schema.breadcrumb.home', { ns: 'common' }),
      },
    } satisfies ListItem;
    const catalogueItem = {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}/catalogue`,
        name: t('schema.breadcrumb.catalogue', { ns: 'common' }),
      },
    } satisfies ListItem;

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [homeItem, catalogueItem],
    };
  }
}

export class CountDomain {
  private total: number;

  constructor(total: number) {
    this.total = total;
  }

  public getTotal(): number {
    return this.total;
  }
}

export default CatalogueDomain;
