import React from 'react';
import { Drawer } from 'vaul';

import Avatar from '@/core/components/Avatar';
import Button from '@/core/components/Button';
import * as CatalogueSearch from '@/core/components/CatalogueSearch';
import DonnonsLogo from '@/core/components/DonnonsLogo';
import Icons from '@/core/components/Icons';
import NotifBadge from '@/core/components/NotifBadge';
import ProfileMenu from '@/core/components/ProfileMenu';
import useDisclosure from '@/core/hooks/useDisclosure';
import useOpenGive from '@/core/hooks/useOpenGive';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import Store from '@/core/lib/new-architecture/store';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const Header: React.FC = () => {
  const { Link: RouterLink, currentPage, push } = useRouterContext();
  const { isLoggedIn } = useAuthContext();

  const { data: current } = Store.current.useCurrent();
  const info = current?.getInfo();

  const { data: conversations } = Store.conversations.useConversations();
  const { data: favorites } = Store.favorites.useFavorites();

  const conversationsUnread = conversations?.getUnread() ?? 0;
  const favoritesUnread = favorites?.getUnread() ?? 0;

  const { t } = useTranslationContext(['common']);
  const { send } = useAnalyticsContext();

  const { onOpenGive } = useOpenGive();

  const onClickOpenGive = async () => {
    await send({ event: AnalyticsEventType.CLICK_GIVE, where: 'NavBar' });
    onOpenGive();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Drawer.Root direction="right" open={isOpen} onClose={onClose}>
        <Drawer.Portal>
          <Drawer.Overlay className="fixed left-0 top-0 z-50 h-screen w-screen overflow-hidden bg-bg-dark/50" onClick={onClose} />
          <Drawer.Content className="fixed right-0 top-0 z-[60] h-svh w-screen overflow-y-auto overflow-x-hidden bg-bg-primary lg:w-1/3" onClickCapture={onClose}>
            <ProfileMenu />
          </Drawer.Content>
        </Drawer.Portal>
        <RouterLink href={new Routes.HomeRoute()} className="flex h-header-icon items-center justify-center lg:hidden">
          <DonnonsLogo />
        </RouterLink>
        <header className="sticky top-0 z-40 flex h-header w-full justify-center gap-8 bg-bg-primary px-4 shadow-[0px_12px_12px_0px_rgba(0,0,0,0.04)] lg:flex-row lg:justify-between lg:border-b lg:border-b-stroke-default-focus lg:px-6 lg:py-4 lg:shadow-none xl:px-24">
          <div className="hidden shrink-0 items-center gap-x-10 lg:flex">
            <RouterLink href={new Routes.HomeRoute()} className="hidden items-center justify-start lg:flex lg:h-20">
              <DonnonsLogo hideText />
            </RouterLink>

            <RouterLink href={new Routes.ExternalRoute('vision')} className="text-body-secondary hidden font-normal text-content-secondary lg:block">
              {t('components.header.vision', { ns: 'common' })}
            </RouterLink>
          </div>
          <div className="flex w-full items-center justify-center gap-4 lg:shrink lg:gap-8">
            <CatalogueSearch.CatalogueSearchButton />
            <div className="relative flex items-center lg:hidden">
              <Icons icon="menu" onClick={onOpen} />
              <button type="button" className="absolute -top-3 left-4" onClick={onOpen} aria-label={t('components.header.open', { ns: 'common' })}>
                <NotifBadge unread={conversationsUnread + favoritesUnread} size="small" />
              </button>
            </div>
          </div>
          <div className="hidden shrink-0 lg:block">
            {isLoggedIn && info ? (
              <div className="flex items-center gap-6">
                <div className="w-max">
                  <Button onClick={onClickOpenGive} variant="give" size="medium" iconLeft="add">
                    {t('components.header.give', { ns: 'common' })}
                  </Button>
                </div>
                <RouterLink href={new Routes.FavoritesRoute()} className="relative flex h-8 flex-col items-center justify-center bg-bg-primary md:h-12">
                  <div className="relative">
                    <div className="absolute -top-3 left-4">
                      <NotifBadge unread={favoritesUnread} size="small" />
                    </div>
                    <Icons icon="heart" />
                  </div>
                  <span className="text-caption-primary mx-2 my-1 font-normal text-content-primary">{t('components.header.heart', { ns: 'common' })}</span>
                </RouterLink>
                <RouterLink href={new Routes.ConversationsRoute()} className="relative flex h-8 flex-col items-center justify-center bg-bg-primary md:h-12">
                  <div className="relative">
                    <div className="absolute -top-3 left-4">
                      <NotifBadge unread={conversationsUnread} size="small" />
                    </div>
                    <Icons icon="message-square" />
                  </div>
                  <span className="text-caption-primary mx-2 my-1 font-normal text-content-primary">{t('components.header.mail', { ns: 'common' })}</span>
                </RouterLink>
                <button
                  type="button"
                  onClick={onOpen}
                  className="relative flex h-8 flex-col items-center justify-center bg-bg-primary md:h-12"
                  aria-label={t('components.header.profile', { ns: 'common' })}
                >
                  <Avatar avatarUrl={info.avatarUrl} size="32" />
                </button>
              </div>
            ) : (
              <div className="hidden shrink-0 gap-6 lg:flex">
                <div className="w-max">
                  <Button
                    onClick={async () => {
                      await send({ event: AnalyticsEventType.CLICK_AUTH, where: 'NavBar + Give' });

                      const clone = currentPage.clone({ openGive: true });

                      currentPage.updateCommon(prev => ({ ...prev, openAuth: true, redirectTo: clone.resolve() }));
                      push(currentPage);
                    }}
                    variant="give"
                    size="medium"
                    iconLeft="add"
                    id="donate"
                  >
                    {t('components.header.give', { ns: 'common' })}
                  </Button>
                </div>
                <div className="shrink-0">
                  <Button
                    variant="secondary"
                    size="medium"
                    onClick={async () => {
                      await send({ event: AnalyticsEventType.CLICK_AUTH, where: 'NavBar + Login' });
                      currentPage.updateCommon(prev => ({ ...prev, openAuth: true }));
                      push(currentPage);
                    }}
                    id="auth"
                  >
                    {t('components.header.login', { ns: 'common' })}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </header>
      </Drawer.Root>
      <CatalogueSearch.CatalogueSearchDialog />
    </>
  );
};

export default Header;
