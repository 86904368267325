import omit from 'lodash/omit';
import { parse } from 'regexparam';
import * as yup from 'yup';

import { DONATIONS_SIZE } from '@/core/lib/constants';
import { PushActionType } from '@/core/lib/notification/notification.context';
import type { CommonSearchParams, Empty, UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute, empty } from '@/core/lib/router/route';

class HomeRoute extends AbstractRoute {
  static path = '/';

  getPath() {
    return HomeRoute.path;
  }

  static isPrivate = false;

  static test(url: string): boolean {
    if (url === '/fr') return true;

    return parse(this.path).pattern.test(url);
  }

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): HomeRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new HomeRoute(common);
  }

  isPrivate() {
    return HomeRoute.isPrivate;
  }

  resolve() {
    return `/${this.computeParams()}`;
  }

  clone(common: CommonSearchParams = {}): HomeRoute {
    const clone = HomeRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

class CguRoute extends AbstractRoute {
  static path = '/cgu';

  getPath() {
    return CguRoute.path;
  }

  static isPrivate = false;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): CguRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new CguRoute(common);
  }

  isPrivate() {
    return CguRoute.isPrivate;
  }

  resolve() {
    return `/cgu${this.computeParams()}`;
  }

  clone(common: CommonSearchParams = {}): CguRoute {
    const clone = CguRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

const cataloguePageParamsSchema = yup.object({ cat: yup.string().optional(), loc: yup.string().optional() });
const catalogueSearchParamsSchema = yup.object({
  distance: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional(),
  page: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional(),
  size: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional()
    .default(DONATIONS_SIZE),
  title: yup.string().optional(),
  sort: yup.string().optional(),
});

type CatalogueParams = yup.InferType<typeof cataloguePageParamsSchema> & yup.InferType<typeof catalogueSearchParamsSchema>;

class CatalogueRoute extends AbstractRoute {
  private params: CatalogueParams;

  static path = '/catalogue';

  static withCategoryAndLoc = '/catalogue/:cat/:loc';

  static withLoc = '/catalogue/ville/:loc';

  static withCategory = '/catalogue/categorie/:cat';

  getPath() {
    if (this.params?.loc && this.params?.cat) {
      return CatalogueRoute.withCategoryAndLoc;
    }
    if (this.params?.loc && !this.params?.cat) {
      return CatalogueRoute.withLoc;
    }
    if (!this.params?.loc && this.params?.cat) {
      return CatalogueRoute.withCategory;
    }

    return CatalogueRoute.path;
  }

  constructor(params: Partial<CatalogueParams>, common?: CommonSearchParams) {
    super(common);
    const inferred = {
      ...cataloguePageParamsSchema.cast(params, { stripUnknown: true, assert: false }),
      ...catalogueSearchParamsSchema.cast(params, { stripUnknown: true, assert: false }),
    };
    this.params = inferred;
  }

  // super charge the common method because there is several url
  static test(url: string): boolean {
    return [parse(this.path).pattern.test(url), parse(this.withLoc).pattern.test(url), parse(this.withCategory).pattern.test(url), parse(this.withCategoryAndLoc).pattern.test(url)].some(res => !!res);
  }

  static exec(path: string) {
    const parsed = parse(this.path);
    const parsedWithLoc = parse(this.withLoc);
    const parsedWithCategory = parse(this.withCategory);
    const parsedWithCategoryAndLoc = parse(this.withCategoryAndLoc);

    const keysAndMatches = [
      [parsed.keys, parsed.pattern.exec(path)],
      [parsedWithLoc.keys, parsedWithLoc.pattern.exec(path)],
      [parsedWithCategory.keys, parsedWithCategory.pattern.exec(path)],
      [parsedWithCategoryAndLoc.keys, parsedWithCategoryAndLoc.pattern.exec(path)],
    ].find(match => !!match[1]);

    if (!keysAndMatches) return {};

    const [keys, matches] = keysAndMatches;

    if (!keys || !matches) return {};

    const out = keys.reduce((acc, key, index) => ({ ...acc, [key]: matches[index + 1] ?? null }), {});

    return out;
  }

  static useParams(): UseParamsReturn<typeof cataloguePageParamsSchema, typeof catalogueSearchParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const page = cataloguePageParamsSchema.cast(query, { stripUnknown: true, assert: false });
    const search = catalogueSearchParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page, search, common };
  }

  static init(url: URL): CatalogueRoute {
    const params = this.exec(url.pathname);
    const page = cataloguePageParamsSchema.cast(params, { stripUnknown: true, assert: true });

    const all = Array.from(url.searchParams.entries()).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as CommonSearchParams);

    const search = catalogueSearchParamsSchema.cast(all, { stripUnknown: true, assert: true });

    return new CatalogueRoute({ ...page, ...search }, omit(all, Object.keys(search)));
  }

  isPrivate() {
    return CatalogueRoute.isPrivate;
  }

  resolve() {
    let base = '/catalogue';

    const withCategoryAndLoc = '/catalogue/:cat/:loc';
    const withLoc = '/catalogue/ville/:loc';
    const withCategory = '/catalogue/categorie/:cat';

    let distance: number | undefined;
    const sort = 'date';

    if (this.params?.loc && this.params?.cat) {
      base = withCategoryAndLoc.replace(':cat', this.params?.cat).replace(':loc', this.params?.loc);
      distance = this.params.distance;
    } else if (this.params?.loc && !this.params?.cat) {
      base = withLoc.replace(':loc', this.params?.loc);
      distance = this.params.distance;
    } else if (!this.params?.loc && this.params?.cat) {
      base = withCategory.replace(':cat', this.params?.cat);
    }

    return `${base}${this.computeParams({
      distance,
      page: this.params.page,
      title: this.params.title,
      sort,
    })}`;
  }

  clone(common: CommonSearchParams = {}): CatalogueRoute {
    const clone = CatalogueRoute.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

type ExternalPaths = 'facebook' | 'faq' | 'vision' | 'blog' | 'playStore' | 'appStore';
class ExternalRoute extends AbstractRoute {
  static path = '';

  getPath() {
    return ExternalRoute.path;
  }

  static paths = {
    vision: 'https://corporate.donnons.org/notre-adn',
    blog: 'https://blog.donnons.org',
    facebook: 'https://www.facebook.com/donnons.org',
    faq: 'https://help.donnons.org/support/home',
    playStore: 'https://play.google.com/store/apps/details?id=com.sopheos.donnons&hl=fr',
    appStore: 'https://apps.apple.com/fr/app/donnons-dons-dobjets/id6471839238',
  };

  private href: string;

  static isPrivate = false;

  public isExternal = true;

  constructor(keyPath: ExternalPaths) {
    super();

    this.href = ExternalRoute.paths[keyPath];
  }

  isPrivate() {
    return ExternalRoute.isPrivate;
  }

  resolve() {
    return this.href;
  }

  clone(): ExternalRoute {
    return this;
  }
}

const msgGiver = yup.object({
  type: yup.mixed<PushActionType>().oneOf([PushActionType.MSG_GIVER]).required(),
  uuid: yup.string().required(),
  conversation: yup.number().required(),
});

const msgTaker = yup.object({
  type: yup.mixed<PushActionType>().oneOf([PushActionType.MSG_TAKER]).required(),
  uuid: yup.string().required(),
  conversation: yup.number().required(),
});

const savedSearch = yup.object({
  type: yup.mixed<PushActionType>().oneOf([PushActionType.SAVED_SEARCH]).required(),
  uuid: yup.string().required(),
  donation: yup.number().required(),
});

const savedSearches = yup.object({
  type: yup.mixed<PushActionType>().oneOf([PushActionType.SAVED_SEARCHES]).required(),
  uuid: yup.string().required(),
});

type NotificationsWebRouteParams = typeof msgGiver | typeof msgTaker | typeof savedSearch | typeof savedSearches | Empty;

type NotificationsWebRouteParamsInferred = yup.InferType<NotificationsWebRouteParams>;

class NotificationsWeb extends AbstractRoute {
  static path = 'notifications-web';

  getPath() {
    return NotificationsWeb.path;
  }

  static isPrivate = false;

  private data: NotificationsWebRouteParamsInferred;

  constructor(data: NotificationsWebRouteParamsInferred, common?: CommonSearchParams) {
    super(common);
    this.data = data;
  }

  static useParams(): UseParamsReturn<Empty, NotificationsWebRouteParams> {
    const { common, query } = AbstractRoute.useCommonParams();

    let search = empty.cast({});

    const json = JSON.parse(query.data ?? '{}');
    const data = 'FCM_MSG' in json ? json.FCM_MSG.data : json;

    switch (data.type) {
      case PushActionType.MSG_GIVER:
        search = msgGiver.cast(data, { stripUnknown: true, assert: false });
        break;

      case PushActionType.MSG_TAKER:
        search = msgTaker.cast(data, { stripUnknown: true, assert: false });
        break;

      case PushActionType.SAVED_SEARCH:
        search = savedSearch.cast(data, { stripUnknown: true, assert: false });
        break;

      case PushActionType.SAVED_SEARCHES:
        search = savedSearches.cast(data, { stripUnknown: true, assert: false });
        break;

      default:
        break;
    }

    return { page: {}, search, common };
  }

  static init(url: URL): NotificationsWeb {
    const common = this.searchParamsToCommon(url.searchParams);
    const dataString = url.searchParams.get('data');
    const json = dataString ? JSON.parse(dataString) : null;
    const data = 'FCM_MSG' in json ? json.FCM_MSG.data : json;

    let casted = empty.cast({});

    switch (data?.type) {
      case PushActionType.MSG_GIVER:
        casted = msgGiver.cast(data, { stripUnknown: true, assert: false });
        break;

      case PushActionType.MSG_TAKER:
        casted = msgTaker.cast(data, { stripUnknown: true, assert: false });
        break;

      case PushActionType.SAVED_SEARCH:
        casted = savedSearch.cast(data, { stripUnknown: true, assert: false });
        break;

      case PushActionType.SAVED_SEARCHES:
        casted = savedSearch.cast(data, { stripUnknown: true, assert: false });
        break;

      default:
        break;
    }

    return new NotificationsWeb(casted, common);
  }

  isPrivate() {
    return NotificationsWeb.isPrivate;
  }

  resolve() {
    return `notifications-web${this.computeParams({ data: JSON.stringify(this.data) })}`;
  }

  clone(common: CommonSearchParams = {}): NotificationsWeb {
    const clone = NotificationsWeb.init(new URL(`http://doesnotmatter.com${this.resolve()}`));
    clone.updateCommon(prev => ({ ...prev, ...common }));
    return clone;
  }
}

export default { HomeRoute, CguRoute, CatalogueRoute, ExternalRoute, NotificationsWeb };
